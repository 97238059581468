<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
                class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
        <div class="mt-6 flex items-center justify-between px-6">
            <h4>Editar almácen</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
        </div>
        <vs-divider class="mb-0"></vs-divider>

        <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
            <div class="p-6">
                <!-- Name -->
                <vs-input @blur="warehouse.name= trimString(warehouse.name)" label="Nombre" v-model="warehouse.name"
                          class="w-full" name="name"
                          v-validate="'required'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('name')">{{ errors.first('name') }}</span>

                <!-- Department -->
                <vs-input @blur="warehouse.department= trimString(warehouse.department)" label="Departamento"
                          v-model="warehouse.department" class="mt-5 w-full" name="department"
                          v-validate="'required'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('department')">{{ errors.first('department') }}</span>

                <!-- Province -->
                <vs-input @blur="warehouse.province= trimString(warehouse.province)" label="Provincia"
                          v-model="warehouse.province" class="mt-5 w-full" name="province"
                          v-validate="'required'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('province')">{{ errors.first('province') }}</span>

                <!-- District -->
                <vs-input @blur="warehouse.district= trimString(warehouse.district)" label="Distrito"
                          v-model="warehouse.district" class="mt-5 w-full" name="district"
                          v-validate="'required'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('district')">{{ errors.first('district') }}</span>

                <!-- Latitude -->
                <vs-input @blur="warehouse.lat= trimString(warehouse.lat)" label="Latitude"
                          v-model="warehouse.lat" class="mt-5 w-full" name="lat"
                          v-validate="'required'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('lat')">{{ errors.first('lat') }}</span>

                <!-- Longitude -->
                <vs-input @blur="warehouse.long= trimString(warehouse.long)" label="Longitud"
                          v-model="warehouse.long" class="mt-5 w-full" name="long"
                          v-validate="'required'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('long')">{{ errors.first('long') }}</span>

                <!-- Ubigeo -->
                <vs-input @blur="warehouse.ubigeo= trimString(warehouse.ubigeo)" label="Ubigeo"
                          v-model="warehouse.ubigeo" class="mt-5 w-full" name="ubigeo"
                          v-validate="'required'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('ubigeo')">{{ errors.first('ubigeo') }}</span>

                <!-- Address -->
                <vs-input @blur="warehouse.address= trimString(warehouse.address)" label="Dirección"
                          v-model="warehouse.address" class="mt-5 w-full" name="address"
                          v-validate="'required'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('address')">{{ errors.first('address') }}</span>

                <!-- Business Name -->
                <vs-input @blur="warehouse.businessName= trimString(warehouse.businessName)" label="Razón social"
                          v-model="warehouse.businessName" class="mt-5 w-full" name="businessName"
                          v-validate="'required'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('businessName')">{{ errors.first('businessName') }}</span>

                <!-- RUC -->
                <vs-input @blur="warehouse.ruc= trimString(warehouse.ruc)" label="Número de RUC"
                          v-model="warehouse.ruc" class="mt-5 w-full" name="ruc"
                          type="number"
                          v-validate="'required'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('ruc')">{{ errors.first('ruc') }}</span>
            </div>
        </component>

        <div class="flex flex-wrap items-center p-6" slot="footer">
            <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Actualizar</vs-button>
            <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
            <div v-if="progress" style="margin-top: 1rem; width: 100%">
                <vs-progress indeterminate color="primary"></vs-progress>
            </div>
        </div>
    </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import trimString from "../../mixins/trimString"

  const firebase = require("firebase")
  // Required for side-effects
  require("firebase/firestore")
  let db = firebase.firestore()
  export default {
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        }
      }
    },
    components: {
      VuePerfectScrollbar
    },
    mixins: [trimString],
    data() {
      return {
        warehouse: {
          id: null,
          name: null,
          address: null,
        },
        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60
        },
        progress: false
      }
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        let data = JSON.parse(JSON.stringify(this.data))
        this.warehouse = {
          ...data
        }
        this.$validator.reset()
      }
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
      },
      scrollbarTag() {
        return this.$store.getters.scrollbarTag
      }
    },
    methods: {
      submitData() {
        this.$validator.validateAll().then(async result => {
          if (result) {
            this.progress = true
            // Update warehouse
            let warehouseRef = db.collection('warehouses').doc(this.warehouse.id)
            warehouseRef.update({
              ...this.warehouse,
              uid: firebase.auth().currentUser.uid,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp()
            }).then(() => {
              this.$store.dispatch('warehouses/updateWarehouse', {warehouse: this.warehouse})
              this.progress = false
              this.$vs.notify({
                color: 'success',
                title: 'Almacén',
                text: 'Almacén modificado correctamente.'
              })
              this.$emit('closeSidebar')
            }).catch(() => {
              this.progress = false
              this.$vs.notify({
                color: 'warning',
                title: '¡Oh no!',
                text: 'Hubo un error.'
              })
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
    .add-new-data-sidebar {
        ::v-deep .vs-sidebar--background {
            z-index: 52010;
        }

        ::v-deep .vs-sidebar {
            z-index: 52010;
            width: 400px;
            max-width: 90vw;

            .img-upload {
                margin-top: 2rem;

                .con-img-upload {
                    padding: 0;
                }

                .con-input-upload {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .scroll-area--data-list-add-new {
        // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
        height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

        &:not(.ps) {
            overflow-y: auto;
        }
    }
</style>
